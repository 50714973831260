import * as React from 'react'
import { Dispatch, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import {
  getTelemetryTypeUnit,
  inchToFeetAndInches,
  inchToBbl,
} from '../utils/converter'
import moment from 'moment'

import {
  Theme,
  createStyles,
  WithStyles,
  Link,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
  LinearProgress,
  Zoom,
} from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import { actionCreators, defaultPageSize } from '../../store/Alert'
import { Site, SitesState } from '../../store/Site'
import { siteRoute, idRoute } from '../../App'
import { renderStatus } from '../details/utils'
import { Alert, MetaData } from '../../api/alertservice'
import { AppState } from '../../store/AppState'
import { ChatOutlined } from '@material-ui/icons'
import theme from '../../theme'
import {
  ColumnProps,
  Column,
  Table,
  TableCellProps,
  AutoSizer,
  SortDirection,
  InfiniteLoader,
  IndexRange,
  OverscanIndexRange,
  Index,
  TableRowProps,
} from 'react-virtualized'
import 'react-virtualized/styles.css'
import AcknowledgeDialog from './AcknowledgeDialog'
import {
  TableCellDataGetterParams,
  defaultCellDataGetter,
  defaultRowRenderer,
} from 'react-virtualized/dist/es/Table'
import {
  hasSupervisorUserAccessLevel,
  hasBasicRole,
} from '../../store/oidc/userManager'
import { getReadableTelemetryType } from 'components/utils/converter'
import { MultitenantUserState } from 'store/reducers/multitenant'
import { TelemetryType } from '../../api/apiservice'

const defaultOverscanRowCount = defaultPageSize / 2

const styles = (_: Theme) =>
  createStyles({
    timeRangePicker: {
      position: 'absolute',
      zIndex: 10,
      alignSelf: 'flex-end',
    },
  })

interface RawAsset {
  id: string
  name?: string
}

interface PropsFromState {
  readonly accessToken?: string
  readonly userId?: string
  readonly tenantId: number
  readonly sites: SitesState
  readonly mySiteIds: string[]
  readonly user: MultitenantUserState
}

type PropsFromDispatch = Pick<typeof actionCreators, 'requestAcknowledge'>

export enum Columns {
  Site = 0,
  Asset = 1,
  Metric = 2,
  From = 3,
  StatusChange = 4,
  To = 5,
  Time = 6,
  AcknowledgedBy = 7,
}

export interface Props {
  readonly isAcknowledged?: boolean
  readonly siteId?: string
  readonly alerts: Alert[][]
  readonly onAlert?: (ack: Alert) => void
  readonly onSort?: (sortBy: string, ascending: boolean) => void
  readonly defaultOrder: Columns
  readonly defaultDirectionAsc: boolean
  readonly isRowLoaded: (index: number) => boolean
  readonly loadPage: (startIndex: number) => Promise<void>
  readonly alertsCount: number
}

type AllProps = Props &
  PropsFromState &
  PropsFromDispatch &
  WithStyles<typeof styles>

const columnCaptions = [
  'Site',
  'Asset',
  'Metric',
  'From',
  'Status Change',
  'To',
  'Time',
  'Acknowledged By',
  'Acknowledge Time',
]

interface State {
  readonly acknowledging: string[]
  readonly alertToConfirm?: Alert
  readonly orderBy: Columns
  readonly directionAsc: boolean
}

class AlertsView extends React.Component<AllProps, State> {
  constructor(props: AllProps) {
    super(props)

    const { defaultOrder, defaultDirectionAsc } = props

    this.state = {
      orderBy: defaultOrder,
      directionAsc: defaultDirectionAsc,
      acknowledging: [],
    }
  }

  public render() {
    return (
      <div style={{ height: '74vh' }}>
        {this.renderPaging()}
        {this.state.alertToConfirm &&
          this.renderConfirmDialog(this.state.alertToConfirm)}
      </div>
    )
  }

  private renderPaging() {
    return (
      <InfiniteLoader
        isRowLoaded={this.isRowLoaded}
        loadMoreRows={({ startIndex }) => {
          return this.props.loadPage(startIndex)
        }}
        rowCount={this.props.alertsCount}
        minimumBatchSize={defaultPageSize}
      >
        {({ onRowsRendered, registerChild }) => {
          return (
            <AutoSizer>
              {({ height, width }) => {
                return this.renderTable(
                  height,
                  width,
                  onRowsRendered,
                  registerChild
                )
              }}
            </AutoSizer>
          )
        }}
      </InfiniteLoader>
    )
  }

  private readonly isRowLoaded = ({ index }: Index): boolean =>
    this.props.isRowLoaded(index)

  private readonly onSort = (sortBy: string, directionAsc: boolean) => {
    const orderBy = Columns[Columns[parseInt(sortBy, 10)]]

    this.setState(
      { directionAsc, orderBy },
      () => this.props.onSort && this.props.onSort(sortBy, directionAsc)
    )
  }

  private renderTable(
    height: number,
    width: number,
    onRowsRendered: (info: IndexRange & OverscanIndexRange) => void,
    registerChild: (registeredChild: any) => void
  ) {
    const { orderBy, directionAsc } = this.state
    const columns: ColumnProps[] = Object.values(Columns)
      .filter((c) => typeof c === 'number')
      .filter((c) => !this.props.siteId || c !== Columns.Site)
      .map((c) => this.getColumn(c as Columns))

    return (
      <Table
        height={height}
        width={width}
        gridStyle={{ direction: 'inherit' }}
        rowCount={this.props.alertsCount}
        rowGetter={this.rowGetter}
        rowHeight={72}
        headerHeight={48}
        sortBy={orderBy.toString()}
        sortDirection={directionAsc ? 'ASC' : 'DESC'}
        sort={(info) =>
          this.onSort(info.sortBy, info.sortDirection === SortDirection.ASC)
        }
        onRowsRendered={onRowsRendered}
        ref={registerChild}
        noRowsRenderer={() => (
          <div style={{ textAlign: 'center' }}>No alerts</div>
        )}
        rowRenderer={this.renderRow}
        overscanRowCount={defaultOverscanRowCount}
      >
        {columns.map(({ dataKey, cellRenderer, ...other }) => {
          return (
            <Column
              key={dataKey}
              dataKey={dataKey.toString()}
              cellRenderer={cellRenderer}
              label={columnCaptions[dataKey]}
              {...other}
            />
          )
        })}
      </Table>
    )
  }

  private readonly renderRow = (props: TableRowProps) => {
    if (props.rowData) {
      return defaultRowRenderer(props)
    }

    const style = {
      ...props.style,
      padding: theme.spacing(0.5),
    }
    delete style.paddingRight

    const linearProgressComponent = (
      <div key={props.index} style={style}>
        <LinearProgress style={{ width: '100%' }} />
      </div>
    )

    const prevRowIndex = props.index - 1
    return this.isRowLoaded({
      index: prevRowIndex,
    })
      ? linearProgressComponent
      : null
  }

  private readonly rowGetter = ({ index }: Index) => {
    if (!this.isRowLoaded({ index })) {
      return undefined
    }

    const pageIndex = Math.floor(index / defaultPageSize)
    const position = index % defaultPageSize
    const page = this.props.alerts[pageIndex]

    return page ? page[position] : undefined
  }

  private readonly getColumn = (col: Columns): ColumnProps => {
    return {
      label: columnCaptions[col],
      dataKey: col,
      width: 100,
      flexGrow: 3,
      cellRenderer: (props: TableCellProps) => this.getCellRenderer(props, col),
      cellDataGetter: (params: TableCellDataGetterParams) =>
        params.rowData ? defaultCellDataGetter(params) : undefined,
      disableSort: col === Columns.AcknowledgedBy && !this.props.isAcknowledged,
    }
  }

  private readonly getCellRenderer = (
    { rowData }: TableCellProps,
    col: Columns
  ) => {
    const alert = rowData as Alert

    if (!alert) {
      return ''
    }

    const {
      id,
      siteId,
      assetId,
      telemetryStatus,
      telemetryType,
      previousTelemetryStatus,
      acknowledgement,
      telemetryTimestamp,
      assetName,
      previousTelemetryValue,
      telemetryValue,
      metaData,
    } = alert

    const isGateway = assetName === 'Gateway'

    switch (col) {
      case Columns.Site:
        return (
          <Link
            color="primary"
            component={RouterLink}
            to={generatePath(siteRoute + idRoute, { id: siteId })}
          >
            {this.mapSiteName(siteId)}
          </Link>
        )

      case Columns.Asset:
        return this.renderAsset(siteId!, alert.assetId!, isGateway)

      case Columns.Metric:
        return getReadableTelemetryType(telemetryType)
      case Columns.From:
        return this.renderTelemetryValue(
          previousTelemetryValue,
          telemetryType,
          siteId,
          assetId,
          metaData
        )
      case Columns.StatusChange:
        return renderStatus(telemetryStatus, previousTelemetryStatus)
      case Columns.To:
        return this.renderTelemetryValue(
          telemetryValue,
          telemetryType,
          siteId,
          assetId,
          metaData
        )
      case Columns.Time:
        return this.renderDate(telemetryTimestamp)

      case Columns.AcknowledgedBy:
        switch (telemetryType) {
          case TelemetryType.GatewayMetrics:
            return acknowledgement?.userId
              ? this.renderUser(alert)
              : hasSupervisorUserAccessLevel(
                  this.props.user.selectedTenant?.roles
                )
              ? this.renderAckButton(
                  alert,
                  this.state.acknowledging.includes(id!)
                )
              : ''
          default:
            return acknowledgement?.userId
              ? this.renderUser(alert)
              : hasSupervisorUserAccessLevel(
                  this.props.user.selectedTenant?.roles
                ) || hasBasicRole(this.props.user.selectedTenant?.roles)
              ? this.renderAckButton(
                  alert,
                  this.state.acknowledging.includes(id!)
                )
              : ''
        }

      default:
        return ''
    }
  }

  private readonly renderTelemetryValue = (
    telemetryValue: number,
    telemetryType: TelemetryType,
    siteId: string,
    assetId: string,
    metaData: MetaData
  ) => {
    switch (telemetryType) {
      case TelemetryType.TankLevel:
      case TelemetryType.BalancedTank:
        const site = this.getSite(siteId)
        const tank = site?.tanks.find((t) => t.id === assetId)

        const [ft, ins] = inchToFeetAndInches(telemetryValue)
        const volume = tank
          ? inchToBbl(telemetryValue, tank.height, tank.capacity).toFixed(1)
          : undefined

        return (
          <React.Fragment>
            {`${ft}' ${ins.toFixed(1)}"`}
            <br />
            {volume ? `${volume} bbl` : 'N/A'}
          </React.Fragment>
        )

      case TelemetryType.LiquidFlow:
        const telemetryValueBPD = telemetryValue * 24
        return (
          <React.Fragment>
            <div
              style={{
                fontSize: '18px',
                marginTop: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {`${telemetryValue.toFixed(1)}`}{' '}
              <strong>
                {' '}
                {getTelemetryTypeUnit(
                  telemetryType,
                  metaData == null || metaData.unitOfMeasure == null
                    ? 'BPH'
                    : metaData.unitOfMeasure
                )}{' '}
              </strong>
            </div>
            <div
              style={{
                fontSize: '14px',
                marginTop: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <strong>
                {`${telemetryValueBPD.toFixed(1)}`} {'BPD'}{' '}
              </strong>
            </div>
          </React.Fragment>
        )
      case TelemetryType.GatewayMetrics:
        return (
          <React.Fragment>
            {`${telemetryValue.toFixed(0)}`}{' '}
            <strong>
              {' '}
              {getTelemetryTypeUnit(
                telemetryType,
                metaData == null || metaData.unitOfMeasure == null
                  ? 'PSIG'
                  : metaData.unitOfMeasure
              )}{' '}
            </strong>
          </React.Fragment>
        )
      default:
        return (
          <React.Fragment>
            {`${telemetryValue.toFixed(1)}`}{' '}
            <strong>
              {' '}
              {getTelemetryTypeUnit(
                telemetryType,
                metaData == null || metaData.unitOfMeasure == null
                  ? 'PSIG'
                  : metaData.unitOfMeasure
              ).toUpperCase()}{' '}
            </strong>
          </React.Fragment>
        )
    }
  }

  private readonly renderAsset = (
    siteId: string,
    assetId: string,
    isGateway = false
  ) => {
    if (isGateway) {
      return (
        <Tooltip title={'Gateway'}>
          <Typography>Gateway</Typography>
        </Tooltip>
      )
    }

    return (
      <Tooltip title={assetId}>
        <Typography>{this.getAssetName(siteId, assetId) ?? ''}</Typography>
      </Tooltip>
    )
  }

  private readonly getAssetName = (
    siteId: string,
    assetId: string
  ): string | undefined => {
    const site = this.getSite(siteId)

    if (!site || !assetId) {
      return assetId
    }

    const {
      casingPressures,
      tubingPressures,
      crankRevolutions,
      tanks,
      gasFlows,
      heaters,
      liquidFlows,
      genericSensors,
      separators,
      compressors,
      staticPressure,
      strokesPerMinute,
      knockoutPressures,
      BalancedTanks,
      flaresStatus,
    }: {
      casingPressures: RawAsset[]
      tubingPressures: RawAsset[]
      crankRevolutions: RawAsset[]
      tanks: RawAsset[]
      gasFlows: RawAsset[]
      heaters: RawAsset[]
      liquidFlows: RawAsset[]
      genericSensors: RawAsset[]
      separators: RawAsset[]
      compressors: RawAsset[]
      staticPressure: RawAsset[]
      strokesPerMinute: RawAsset[]
      knockoutPressures: RawAsset[]
      BalancedTanks: RawAsset[]
      flaresStatus: RawAsset[]
    } = site

    const asset = casingPressures
      .concat(tubingPressures)
      .concat(crankRevolutions)
      .concat(tanks)
      .concat(gasFlows)
      .concat(heaters)
      .concat(liquidFlows)
      .concat(genericSensors)
      .concat(separators)
      .concat(compressors)
      .concat(staticPressure)
      .concat(strokesPerMinute)
      .concat(knockoutPressures)
      .concat(BalancedTanks)
      .concat(flaresStatus)
      .find((item) => item.id === assetId)

    if (asset) {
      const { id, name } = asset

      return name ?? id
    }

    return undefined
  }

  private readonly renderUser = (alert: Alert) => {
    const { acknowledgement } = alert

    if (!acknowledgement) {
      return
    }

    const { note, userEmail, timestamp, userId } = acknowledgement
    const userName = userEmail ? (
      <Link color="primary" href={`mailto:${userEmail}`}>
        {userEmail}
      </Link>
    ) : (
      <React.Fragment>{userId}</React.Fragment>
    )
    const user = timestamp ? (
      <Tooltip title={this.formatDateToRelative(timestamp)}>{userName}</Tooltip>
    ) : (
      userName
    )

    return (
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {note && (
          <Tooltip title={<Typography>{note}</Typography>}>
            <ChatOutlined
              style={{
                marginRight: theme.spacing(0.5),
              }}
            />
          </Tooltip>
        )}
        {user}
      </span>
    )
  }

  private renderAckButton(alert: Alert, loading: boolean) {
    if (!this.props.mySiteIds.includes(alert.siteId!)) {
      return null
    }

    return loading ? (
      <CircularProgress size="2em" />
    ) : (
      <Button
        size="small"
        color="primary"
        variant="contained"
        onClick={() => this.setState({ alertToConfirm: alert })}
      >
        Acknowledge
      </Button>
    )
  }

  private renderDate(creationTimeUtc: Date) {
    const timeStamp = moment(creationTimeUtc)
    const relativeDate = this.formatDateToRelative(timeStamp.toDate())
    const absoluteDate = this.formatDateToFullAbsolute(timeStamp.toDate())

    return (
      <Tooltip title={absoluteDate} TransitionComponent={Zoom} placement="top">
        <Typography>{relativeDate}</Typography>
      </Tooltip>
    )
  }

  private renderConfirmDialog(alertToConfirm: Alert) {
    const {
      sites: { sites },
    } = this.props
    const { telemetryType, siteId } = alertToConfirm
    const site = sites.find((w) => w.id === siteId)
    const type = getReadableTelemetryType(telemetryType)
    const prompt = `Acknowledge the alert for ${type} on ${
      site?.name || siteId
    }?`

    return (
      <AcknowledgeDialog
        prompt={prompt}
        onAction={this.onConfirmDialogAction(alertToConfirm.id!)}
      />
    )
  }

  private readonly onConfirmDialogAction =
    (id: string) => (ok: boolean, note: string) => {
      if (ok) {
        const { accessToken, userId, tenantId } = this.props

        if (accessToken && userId && tenantId) {
          const acknowledging = this.state.acknowledging.concat(id)

          this.setState(
            {
              acknowledging,
              alertToConfirm: undefined,
            },
            () =>
              this.props.requestAcknowledge(
                accessToken,
                id,
                tenantId.toString(),
                userId,
                note,
                this.onAcknowledged
              )
          )
        }
      } else {
        this.setState({
          alertToConfirm: undefined,
        })
      }
    }

  private readonly onAcknowledged = (alert: Alert) => {
    const acknowledging = this.state.acknowledging.filter(
      (id) => id !== alert.id
    )
    const { onAlert } = this.props

    this.setState({ acknowledging }, () => onAlert && onAlert(alert))
  }

  private formatDateToRelative(date?: Date) {
    if (!date) {
      return ''
    }
    const m = moment(date)
    return `${m.fromNow()}`
  }

  private formatDateToFullAbsolute(date?: Date) {
    if (!date) {
      return ''
    }
    const m = moment(date)
    return `${m.format('llll')}`
  }

  private mapSiteName(siteId?: string): string | undefined {
    const site = this.getSite(siteId)

    if (!site) {
      console.warn(`Site with id ${siteId} was not found in state.`)
      return siteId
    }

    return site.name
  }

  private readonly getSite = (id?: string): Site | undefined =>
    this.props.sites.sites.find((d) => d.id === id)
}

const mapStateToProps = (state: AppState): PropsFromState => {
  return {
    sites: state.sites,
    accessToken: state.multitenantUser.accessToken,
    userId: state.multitenantUser.id,
    tenantId: state.multitenantUser.tenants?.find((t) => t.selected)?.id || 0,
    mySiteIds: state.groups.groups.flatMap((g) => g.siteIds),
    user: state.multitenantUser,
  }
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(actionCreators, dispatch)

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AlertsView)
)
