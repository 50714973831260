import * as React from 'react'
import { Typography, Link } from '@material-ui/core'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { siteRoute, idRoute } from '../../App'
import { Site } from '../../store/Site'
import theme from 'theme'

interface Props {
  readonly site: Site
}

function SiteInfoWindow({ site }: Props) {
  const [assetCountsByType] = React.useState(() => {
    return {
      Tanks: site.tanks.length,
      gasFlows: site.gasFlows.length,
      Heaters: site.heaters.length,
    }
  })

  return (
    <div>
      <Typography>
        <Link
          component={RouterLink}
          to={generatePath(siteRoute + idRoute, {
            id: site.id,
          })}
        >
          {site.name || site.id}
        </Link>
      </Typography>
      <Typography>
        Lat/Lon:&nbsp;{site.latitude},&nbsp;
        {site.longitude}
      </Typography>
      <Typography>Assets count:</Typography>
      {Object.entries(assetCountsByType).map(([assetType, count], index) => {
        return (
          <Typography
            key={index}
            variant="subtitle2"
            style={{
              marginLeft: theme.spacing(1),
            }}
          >
            {assetType}: {count}
          </Typography>
        )
      })}
    </div>
  )
}

export default SiteInfoWindow
